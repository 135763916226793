export const FEATURE_FLAGS_TABLE_COLUMNS = [
  {
    title: 'Name', width: '40%', color: '#7B809A', fontSize: "10px"
  },

  {
    title: 'Feature Flag ID', width: '15%', color: '#7B809A', fontSize: "10px"
  },
  {
    title: 'Value', width: '15%', color: '#7B809A', fontSize: "10px"
  },
  {
    title: 'Default value', width: '15%', color: '#7B809A', fontSize: "10px"
  },
  {
    title: 'Date Added', width: '15%', color: '#7B809A', fontSize: "10px"
  },
]

export const MAIN_TEXT_COLOR = '#344767'
