import React, {useMemo, useState} from "react";
import {getOrganizations} from "apis/organizations";
import {Alert, Snackbar} from "@mui/material";
import FilteredTable from "../../custom-components/FilteredTable";
import {StyledTableCell, StyledTableRow, StyledText} from "../../styled/styledComponent";
import SensorsIcon from "../../custom-components/SensorsIcons";
import styled from "styled-components";
import {capitalizeFirstLetter} from "../../utils/string";
import {ReactComponent as ContributorsIcon} from "../../icons/contributors.svg";
import {useNavigate} from "react-router-dom";

const DetailWrapper = styled.div`
    padding: 5px 0;
    display: flex;
    align-items: center;
`


const IconsWrapper = styled.span`
    margin-right: 10px;
    display: flex;
    align-items: center;
`

export const ORGANIZATION_COLUMNS = [
    {title: 'Organization Name', width: '30%', color: '#7B809A', fontSize: "10px"},
    {title: 'Type', width: '15%', color: '#7B809A', fontSize: "10px"},
    {title: 'Contributors', width: '20%', color: '#7B809A', fontSize: "10px"},
    {title: 'Integrations', width: '20%', color: '#7B809A', fontSize: "10px"},
];

const toggleStates = {
    PAID: 'Paid',
    TRIAL: 'Trial',
    FREE: 'Free'
};

const Organizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const navigate = useNavigate();
    const hideSnackbar = () => setSnackbarMessage(null);

    // Apply the hover style on mouse enter
    const rowStyles = {
        cursor: 'pointer',
    };

    const getDetailsTile = (text, Icon) => {
        return <DetailWrapper>
            <IconsWrapper><Icon/></IconsWrapper>
            {text}
        </DetailWrapper>
    }

    const searchOrganizations = async (toggle, currPage, currRowsNum, search) => {
        let result = [];
        let isPaid = true;
        let isTrial = false;
        if (toggle === toggleStates.FREE) {
            isPaid = false;
        } else if (toggle === toggleStates.TRIAL) {
            isPaid = false;
            isTrial = true;
        }
        try {
            const {data: orgDataResp} = await getOrganizations(
                {
                    'page': currPage + 1, 'limit': currRowsNum,
                    'searchParam': search, 'isPaid': isPaid, 'isTrial': isTrial
                });
            setOrganizations(orgDataResp);
            result = orgDataResp;
        } catch (exp) {
            setSnackbarMessage("Failed to load organizations");
            console.error(exp, exp.stack);
        }
        return result;
    };

    const tableRows = useMemo(() => {
        return organizations.map(({
                                      id, sensors, name, license_plan_name: licensePlanName,
                                      contributors_count: contributorsCount,
                                  }, index) => {
            return (
                <StyledTableRow key={index} style={rowStyles}
                                onClick={() => navigate(`/organization/${id}`)}>
                    <StyledTableCell key={index + 'name'}>
                        <StyledText title={name} fontWeight={700}>
                            {name.length > 20 ? name.substring(0, 20) : name}</StyledText>
                    </StyledTableCell>
                    <StyledTableCell key={index + 'licensePlan'}>
                        {capitalizeFirstLetter(licensePlanName)} account
                    </StyledTableCell>
                    <StyledTableCell
                        key={index + 'contributors'}>
                        {getDetailsTile(`${contributorsCount} contributors`, ContributorsIcon)}
                    </StyledTableCell>
                    <StyledTableCell key={index + 'sensor'}>
                        {[].concat(sensors).sort(
                            function (a, b) {
                                if (a.toLowerCase() < b.toLowerCase()) return -1;
                                if (a.toLowerCase() > b.toLowerCase()) return 1;
                                return 0;
                            }).map(function (sensorName) {
                                if (sensorName !== null) {
                                    return (<SensorsIcon name={sensorName.toLowerCase()}/>);
                                }
                                return '';
                            }
                        )}
                    </StyledTableCell>

                </StyledTableRow>
            )
        });
    }, [organizations]);

    return (
        <div>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={Boolean(snackbarMessage)} onClose={hideSnackbar}>
                <Alert onClose={hideSnackbar} severity="error" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <FilteredTable
                defaultState={toggleStates.PAID}
                toggleStatesList={toggleStates}
                tableRows={tableRows}
                tableColumns={ORGANIZATION_COLUMNS}
                updateData={searchOrganizations}
                tableId={'OPATable'}
            />
        </div>
    );
};

export default Organizations;
