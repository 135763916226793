import styled from "styled-components";
import LinearbLogo from "images/linearb_logo_w_text.svg";
import GoogleLogo from "images/google_logo.svg";
import { Flex } from "styled/styledComponent";
import { LINREST_API_GATEWAY_URL } from "constants/apis";



const LoginPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
`

const LinearbLogoWrapper = styled.img`
  height: 3rem;
`
const GoogleLogoWrapper = styled.img`
  margin-right: 0.3rem;`


const LoginPage = () => {
  const loginRedirect = () => {
    const url = `${LINREST_API_GATEWAY_URL}/api/v1/rest/public/auth/identity/backoffice_google_identity`;
    window.location.href = url
  }
  return (
    <LoginPageWrapper>
      <LinearbLogoWrapper src={LinearbLogo} alt='LinearB' />
      Welcome to LinearB's Backoffice portal
      <Flex
        onClick={loginRedirect}
        border='solid 1px black'
        padding='0.3rem'
        borderRadius='0.3rem'
        marginTop='1rem'
        cursor='pointer' >
        <GoogleLogoWrapper src={GoogleLogo} />
        Continue with Google
      </Flex>
    </LoginPageWrapper>
  )
}



export default LoginPage;