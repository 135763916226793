

import React from "react";
import { ReactComponent as Github } from "../icons/github.svg";
import { ReactComponent as Jira } from "../icons/jira.svg";
import { ReactComponent as Slack } from "../icons/slack.svg";
import { ReactComponent as Gitlab } from "../icons/gitlab.svg";
import { ReactComponent as Bitbucket } from "../icons/bitbucket-svgrepo-com.svg";
import { ReactComponent as Saml } from "../icons/saml-svgrepo-com.svg";
import { ReactComponent as Azure } from "../icons/azure-devops-svgrepo-com.svg";

const ICONS_MAP = {
  "github" : <Github style={{width:24,height:24}}/>,
  "jira" : <Jira style={{width:24,height:24}} />,
  "slack" : <Slack style={{width:24,height:24}}/>,
  "gitlab" : <Gitlab style={{width:24,height:24}}/>,
  "bitbucket_server" : <Bitbucket style={{width:24,height:24}}/>,
  "bitbucket": <Bitbucket style={{width:24,height:24}}/>,
  "saml": <Saml style={{width:24,height:24}}/>,
  "azure_devops": <Azure style={{width:24,height:24}}/>,
}

const SensorsIcon = ({name}) => { 
  return ICONS_MAP[name] || (<></>)
 }

export default SensorsIcon;