
import { ExpandMore, Edit } from "@mui/icons-material";
import { AccordionDetails, AccordionSummary, Dialog, DialogTitle, TextField, Tooltip, Button, DialogContent, DialogActions } from "@mui/material";
import { Flex, StyledText, StyledTableRow, StyledAvatar, StyledTableCell } from "styled/styledComponent";
import { StyledAccordion, StyledSwitch } from "./OrganizationDetails.styled"
import CustomTable from "./CustomTable";
import { MAIN_TEXT_COLOR, FEATURE_FLAGS_TABLE_COLUMNS } from "./OrganizationDetails.consts"
import moment from 'moment';
import { useEffect, useState } from "react";
import styled from "styled-components";


const StyledTextField = styled(TextField)`
  width: 320px;
`

const EditSettingDialog = ({
  open,
  onClose,
  setEditingSettingDefinition,
  setting
}) => {
  const [currentValue, setCurrentValue] = useState(setting.value ? JSON.stringify(setting.value) : null)
  const [isValidJson, setIsValidJson] = useState(true)
  const onSaveClicked = () => {
    setEditingSettingDefinition({ definition_id: setting.definition_id, name: setting.name, value: currentValue, from_value: setting.value || setting.default_value })
    onClose()
  }

  useEffect(() => {
    try {
      JSON.parse(currentValue);
      setIsValidJson(true)
    } catch (e) {
      setIsValidJson(false)

    }
  }, [currentValue])

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Edit settings</DialogTitle>
      <DialogContent>
        <StyledText padding="0px 0px 12px 0px">Please set the settings for <strong>{setting.name}</strong></StyledText>
        <StyledTextField
          rows={8}
          multiline
          value={currentValue}
          onChange={e => setCurrentValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!isValidJson}
          onClick={onSaveClicked}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )

}


const OrganizationSettingsSection = ({ settingsList, isFeatureFlags, setEditingSettingDefinition, title , hasRequiredScopes }) => {

  const [filteredSettingsList, setFilteredSettingsList] = useState(settingsList)
  const [editingSetting, setEditingSetting] = useState(null)
  const [accordionExpanded, setAccordionExpanded] = useState(false);
 


  useEffect(() => {
    setFilteredSettingsList(settingsList)
  }, [settingsList])


  const LongTextWithTooltip = ({ stringValue }) => {
    return (
      <Tooltip
        title={stringValue}
        disableHoverListener={stringValue.length < 30}>
        <StyledText>{stringValue.length > 30 ? `${stringValue.substring(0, 30)}...` : stringValue}</StyledText>
      </Tooltip>)
  }




  const ValueCell = ({ setting }) => {


    if (isFeatureFlags) {
      const isFFOn = setting.value !== null ? setting.value : setting.default_value;

      return (<StyledSwitch
        disabled={!hasRequiredScopes}
        checked={isFFOn}
        onChange={(e) => {
          setEditingSettingDefinition({ definition_id: setting.definition_id, name: setting.name, value: e.target.checked, from_value: setting.value || setting.default_value , isFeatureFlags})
        }}
      />)
    }

    return (
      <Flex>
        <LongTextWithTooltip stringValue={setting.value !== null ? JSON.stringify(setting.value) : ''} />
       {hasRequiredScopes && <Edit onClick={() => setEditingSetting(setting)} />}
      </Flex>
    )
  }

  const DefaultValueCell = ({ setting }) => {
    if (isFeatureFlags) {
      return <StyledText>{setting.default_value ? "GA (True)" : "Beta (False)"}</StyledText>
    }
    return <LongTextWithTooltip stringValue={setting.default_value !== null ? JSON.stringify(setting.default_value) : ''} />
  }

  const onSearch = (text) => {
    const searchedItemsList = settingsList.filter(({ name }) => name.toLowerCase().includes(text.toLowerCase()))
    setFilteredSettingsList(searchedItemsList)
  }

  const getTableRows = () => filteredSettingsList.map((setting) => {
    return (
      <StyledTableRow>
        <StyledTableCell>
          <Flex items='center'>
            <StyledAvatar
              fontWeight="700"
              color="#4451BF"
              backgroundColor="#FFFFFF"
              width="48px"
              margin="0px 16px 0px 0px"
              height="48px"
              border="1px solid #4451BF">{setting.name.substring(0, 2).toUpperCase()}</StyledAvatar>
            {setting.name}
          </Flex>
        </StyledTableCell>
        <StyledTableCell>{setting.definition_id}</StyledTableCell>
        <StyledTableCell>
          <ValueCell setting={setting} />
        </StyledTableCell>
        <StyledTableCell><DefaultValueCell setting={setting} /></StyledTableCell>
        <StyledTableCell>{setting.updated_at ? moment(setting.updated_at).format('DD.MM.YYYY') : ''}</StyledTableCell>
      </StyledTableRow>
    )
  })
  return (
    <>
      <StyledAccordion onChange={(e, expanded) => setAccordionExpanded(expanded)} >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Flex items='center' justify='space-between' width="95%">
            <StyledText color={MAIN_TEXT_COLOR} size={16} fontWeight={700}>
              {title}
            </StyledText>
            {accordionExpanded && <TextField
              onClick={e => e.stopPropagation()}
              label="Search"
              variant="outlined"
              onChange={(e) => onSearch(e.target.value)} />}
          </Flex>

        </AccordionSummary>
        <AccordionDetails>
          <CustomTable
            tableRows={getTableRows()}
            tableWidth="100%"
            columns={FEATURE_FLAGS_TABLE_COLUMNS}
          />
        </AccordionDetails>
      </StyledAccordion>
      {Boolean(editingSetting) && <EditSettingDialog
        setting={editingSetting}
        open={Boolean(editingSetting)}
        setEditingSettingDefinition={setEditingSettingDefinition}
        onClose={() => setEditingSetting(null)} />}

    </>

  )
}


export default OrganizationSettingsSection;