import Axios from "./axiosClient"
import { API_ENDPOINT } from "../constants/apis"

export const getProjectsConfig = (organizationId) => {
  return Axios.get(`${API_ENDPOINT}/projects/${organizationId}/config`)
}

export const saveProjectsConfig = (organizationId, configJson) => {
  return Axios.post(`${API_ENDPOINT}/projects/${organizationId}/config`, configJson)
}

