
import styled from "styled-components";
import { Accordion, Switch, Card } from "@mui/material";

export const OrganizationIconWrapper = styled.div`
  height: 80px;
  width: 80px;
  border: solid 1px #4451BF;  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  width: min-content;
`
export const StyledHeaderCard = styled(Card)`
  padding: 16px;
  border-radius: 8px; 
  display: flex;
`

export const TitleTextWrapper = styled.div`
  padding: 0px 24px;
  flex-direction: column;
  display: flex;
  justify-content: center;
`

export const StyledSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.MuiSwitch-track: {
    background-color: 'lightgreen' !important;
  }
  & .MuiSwitch-switchBase.Mui-checked: {
    color: "green"
  }
`

export const StyledAccordion = styled(Accordion)`
  color: red;
  margin :16px 0px;
  border-radius: 8px;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%);
  .MuiPaper-root-MuiAccordion-root:before {
    background-color: red;
  }
`
