import { getOrganizationSensors, getOrganizationDetails, getOrganizationSettings } from "apis/organizations";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";


import _ from "lodash";
import SettingChangeConfirmationDialog from "./SettingChangeConfirmationDialog";
import OrganizationSummary from "./OrganizationSummary";

import OrganizationSettingsSection from "./OrganizationSettingsSection";
import withScopesHOC from "HOC/withScopesHOC";

const OrganizationDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true)
  const [organizationDetails, setOrganizationDetails] = useState({})
  const [organizationSensorsMap, setOrganizationSensorsMap] = useState({})
  const [organizationSettings, setOrganizationSettings] = useState([])
  const [editingSettingDefinition, setEditingSettingDefinition] = useState(null)



  const getData = async () => {
    try {
      const [{ data: organizationDetails }, { data: sensors }, { data: organizationSettings }] = await Promise.all([
        getOrganizationDetails(id),
        getOrganizationSensors(id),
        getOrganizationSettings(id),
      ])
      setOrganizationDetails(organizationDetails)
      setOrganizationSensorsMap(_.groupBy(sensors, 'sensor_type_name'))
      setOrganizationSettings(organizationSettings)

    } catch (exp) { }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])



  const featureFlags = useMemo(() => organizationSettings.filter(({ namespace }) => namespace === 'featureFlags'), [organizationSettings]);
  const settings = useMemo(() => organizationSettings.filter(({ namespace }) => namespace !== 'featureFlags'), [organizationSettings]);


  const onSettingsUpdated = (editedSettingDefinition) => {
    const newOrganizationSettings = organizationSettings.map((item) => item.definition_id === editingSettingDefinition.definition_id ? { ...item, ...editedSettingDefinition } : item)
    setOrganizationSettings([...newOrganizationSettings])
    setEditingSettingDefinition(null)
  }

  const FeatureFlagsWithScopes = withScopesHOC(OrganizationSettingsSection, ["edit_org_ff"])
  const OrganizationSettingsWithScopes = withScopesHOC(OrganizationSettingsSection, ["edit_org_settings"])

  return (
    <>
      <DashboardLayout>
        <OrganizationSummary organizationDetails={organizationDetails} organizationSensorsMap={organizationSensorsMap} />


        <FeatureFlagsWithScopes
          settingsList={featureFlags}
          title="Feature Flags"
          isFeatureFlags={true}
          setEditingSettingDefinition={setEditingSettingDefinition} />
        <OrganizationSettingsWithScopes
          title="Settings"
          settingsList={settings}
          setEditingSettingDefinition={setEditingSettingDefinition} /> 

      </DashboardLayout>
      <SettingChangeConfirmationDialog
        organizationId={organizationDetails.id}
        organizationName={organizationDetails.name}
        settingDefinition={editingSettingDefinition}
        open={Boolean(editingSettingDefinition)}
        onSettingsUpdated={onSettingsUpdated}
        onClose={() => setEditingSettingDefinition(null)} />
    </>

  );
}

export default OrganizationDetails;