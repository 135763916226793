import styled from "styled-components";
import { StyledTable,StyledTableHead,StyledTableBody, StyledTableRow,StyledTableCell } from "styled/styledComponent";


const CustomTable = ({tableRows, columns, tableWidth= '90%' }) => {
  return (
    <StyledTable width={tableWidth} >
      <StyledTableHead width="100%">
        <StyledTableRow >
          {columns.map(({ title, width,color,fontSize }) => <StyledTableCell fontSize={fontSize} width={width} color={color}>{title}</StyledTableCell>)}
        </StyledTableRow>
      </StyledTableHead>
      <StyledTableBody>
        {tableRows}
      </StyledTableBody>
    </StyledTable>)
}




export default CustomTable;