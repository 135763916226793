import axios from 'axios';
import { JWT } from 'constants/local_storage';
import _ from 'lodash';


const UNAUTHORIZED_ERROR_CODES = [403];

const getToken = () => {
  return localStorage.getItem(JWT)
}
const clearToken = () => {
  return localStorage.removeItem(JWT)
}

// Add token interceptor
axios.interceptors.request.use(
  function (config) {
    const token = getToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Content-Type'] = `application/json`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(function (response) {
  return response;
}, function (error) { 
  const responseCode = _.get(error, 'response.status', 0);
  if ( UNAUTHORIZED_ERROR_CODES.includes(Number(responseCode)) ) {
    clearToken();
    let baseUrl = window.location.origin;
    window.location.replace(baseUrl + '/login');
  }
});
 
export default axios