import Axios from "./axiosClient"
import {API_ENDPOINT} from "../constants/apis"


export const getOPAs = ({page = 1, limit = 50, searchParam = null, has_valid_license = true} = {}) => {
    return Axios.get(`${API_ENDPOINT}/on_prem_apps`, {
        params: {
            page: page,
            limit: limit,
            search_param: searchParam,
            has_valid_license: has_valid_license
        }
    });
}

export const updateOrganizationData = (orgId, extra) => {
    return Axios.patch(`${API_ENDPOINT}/organizations/${orgId}/update_info`, extra);
}

export const onBoardNewOrganization = (orgName, accountName, accountEmail) => {
    const payload = {'org_name':  orgName, 'account_name': accountName, 'account_email': accountEmail}
    return Axios.post(`${API_ENDPOINT}/on_prem_apps/create_organization`, payload);
}
