import React, { useEffect } from 'react';
import QueryString from 'query-string';
import Axios from "apis/axiosClient"
import { CircularProgress } from '@mui/material';
import { JWT } from "constants/local_storage";
import { LOGIN_ROUTE } from 'routes';
import { LINREST_API_GATEWAY_URL } from 'constants/apis';
import { useNavigate } from 'react-router-dom';
const OAuthHandler = () => {
    const providerName = 'backoffice_google_identity';
  
  const navigate =useNavigate()
  const search = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  const encodedSearch = QueryString.stringify(
    {
      ...search,
    },
    { encode: true, strict: true }
  );

  const identityUrl = `${LINREST_API_GATEWAY_URL}/api/v1/rest/public/auth/backoffice-identity/success/${providerName}?${encodedSearch}`;


  useEffect(() => {
    window.history.replaceState({}, document.title, '/');
    const validateAndGetToken = async () => {
      try {
        const { data: { token } } = await Axios.get(identityUrl, { withCredentials: true })
        localStorage.setItem(JWT, token);
        navigate('/')
      } catch (exp) {
        navigate(LOGIN_ROUTE.route)
      }
    }
    validateAndGetToken()
  }, [])


  return <CircularProgress />;
};

// export async function postIdentityActions(token, cookieOptions, providerName) {
//   const sessionStorageAccessor = BrowserStorageAccessor().session;
//   const localStorageAccessor = BrowserStorageAccessor().local;
//   storeToken(token, cookieOptions);
//   let account = null;
//   try {
//     account = await getAccount();
//   } catch (e) {
//     console.error(e);
//     window.TrackJS && window.TrackJS.track(e);
//     return validateFetchAccountError(e);
//   }
//    localStorageAccessor.set(
//     LOCAL_STORAGE_KEYS.LAST_PROVIDER_NAME,
//     providerName.replace('_identity', '').toLowerCase()
//   );
//   if (account) {
//     localStorageAccessor.set(LOCAL_STORAGE_KEYS.ACCOUNT_ID, account.id);
//   }
//   if (isGdprCheckboxChecked()) {
//     //user approved GDPR privacy-policy on this flow
//     accountApprovedGdpr();
//   }
// }



export default OAuthHandler;
