import React, { useState, useEffect } from "react";
import { Alert, Button, Snackbar, TextField, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { handleBulkSlackCsv, check_csv_result, downloadCsvResult } from "../../apis/bulkOperations";
import CircularProgress from '@mui/material/CircularProgress';
import withScopesHOC from 'HOC/withScopesHOC';

const BulkOperations = ({hasRequiredScopes}) => {
  const [orgId, setOrgId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(false);
  const [completed, setCompleted] = useState(false); 
  const [resultFileName, setResultFileName] = useState(null);
  const [downloading, setDownloading] = useState(false);


  const [snackbarMessage, setSnackbarMessage] = useState(null);

  const hideSnackbar = () => setSnackbarMessage(null);

  // Handle the user inputs for organization ID
  const handleOrgIdChange = (event) => {    
    setCompleted(false);
    // We don't want to allow non digits
    const inputValue = event.target.value;
    if (
      /^[0-9]*$/.test(inputValue) &&
      !inputValue.includes("e") &&
      !inputValue.includes("-")
    ) {
      setOrgId(inputValue);
    }
  };

  const handleOrgIdEditKeyPress = (event) => {
    // Prevent the input of "-" and "e"
    if (event.key === "-" || event.key === "e") {
      event.preventDefault();
    }
  };

  // Handle the file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // Check if the selected file is a CSV file
    if (file && file.type === "text/csv") {
      reader.onload = (readerEvent) => {
        setSelectedFile(readerEvent.target.result);        
      };      
    } else {
      console.log("The user didn't select a CSV file.");
      setSelectedFile(null);
    }
  };

  const onUploadBulkSlackCsv = async () => {
    
    if (selectedFile && orgId) {
      const formData = new FormData();
      formData.append("orgId", orgId);
      formData.append("file", selectedFile);
      try {
        setCompleted(false);        
        let response = await handleBulkSlackCsv(formData);                      
        if (response.data) {
          const [data, code] = response.data;
          if (code !== 200) {
            setSnackbarMessage("An error occurred when uploading the file: " + data.message);
            console.error("An error occurred when uploading the file: " + data.message);
          } else {  
            console.log('Result file name: ' + data.file_name);        
            setResultFileName(data.file_name);
            setProgress(true);
          }                         
        } else {
            setSnackbarMessage("An error occurred when uploading the file, no result was returned from the server");
            console.error("An error occurred when uploading the file, no result was returned from the server");
        }
      } catch (exp) {
        setSnackbarMessage("Failed to upload file: " + exp.message)
        console.error(exp, exp.stack);
      }
    }
  };

  useEffect(() => {

    const checkResultsReady = async () => {    
      let exists = false;
      try {
        const response = await check_csv_result(orgId, resultFileName);
        if (response.data) {
          const [data, code] = response.data;
          if (code !== 200) {
            setSnackbarMessage("An error occurred when checking the results " + data.message + " " + code);
            console.error("An error occurred when checking the results " + data.message + " " + code);
          } else {            
            // data should have a property called exists with a boolean value                
            if (data && data.exists) {
              console.log('Result exists');
              exists = true;
            }
          }
        }        
      } catch (exp) {
        console.error(exp, exp.stack);
        setSnackbarMessage("Failed to check results: " + exp.message)        
      }
      return exists;
    };

    let timer;
    let timeout;
    if (progress) {      

       // Timeout after 1 minute and 5 seconds
       timeout = setTimeout(() => {
        if (progress) { 
          setProgress(false);
          clearInterval(timer);                    
          setSnackbarMessage('Timeout: No results received');
        }
      }, 65000);

      timer = setInterval(() => {
        // Fetch results from the server
        checkResultsReady().then((exists) => {
          if (exists) {           
            clearInterval(timer);
            clearTimeout(timeout);
            setCompleted(true);       
            setProgress(false);            
          }
        })                                
      }, 5000); // Check server every 5 seconds                

    }

    return () => {
      clearTimeout(timeout);
      clearInterval(timer);      
    };
  }, [progress]); // We don't need to update here when others are modified.


  // Function to trigger file download from server
  const handleDownload = async () => {
    setDownloading(true);
    const response = await downloadCsvResult(orgId, resultFileName);
    if (response.data) {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'downloaded_file.csv');
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
    }
    
  };

  return (
    <div>
      <DashboardNavbar isMini={true} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(snackbarMessage)}
        onClose={hideSnackbar}
      >
        <Alert onClose={hideSnackbar} severity="error" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <DashboardLayout>
        <Accordion defaultExpanded={hasRequiredScopes} disabled={!hasRequiredScopes}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">
            Bulk Slack Link Accounts
            </Typography>

          </AccordionSummary>
          <AccordionDetails id="panel1-content">
            <Typography variant="h6">
              Enter the organization id and the csv file you want to upload
            </Typography>
            <TextField
              type="number"
              key="orgId"
              label="Organization Id"
              variant="outlined"
              fullWidth
              value={orgId}
              onKeyDown={handleOrgIdEditKeyPress}
              onChange={handleOrgIdChange}
              margin="normal"
            />            

            <Typography variant="body2" style={{ marginTop: '12px' }}>
              NOTE: The CSV file should have the following columns (in this order): “account_email“, “contributor_id“ and “provider_id”.
            </Typography>
            <Button
              variant="contained"
              component="label"
              color="primary"
              style={{ color: "white" }}
            >
              Select CSV File
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={handleFileChange}
              />
            </Button>
          </AccordionDetails>
          <AccordionActions>
            <Button>Cancel</Button>
            <Button
              disabled={!orgId || !selectedFile}
              onClick={onUploadBulkSlackCsv}
            >
              Upload
            </Button>
          </AccordionActions>
          <Box display="flex" alignItems="center" justifyContent="center" flex="1" sx={{ marginBottom: 3 }}>
            {progress && (
              <Box display="flex" alignItems="center">
                <CircularProgress />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>Processing... Please wait</Typography>
              </Box>
            )}
             {completed && (
            <Typography variant="body1">
              Click here to download the results
              <Button onClick={handleDownload} disabled={downloading}>
                {downloading ? 'Downloading...' : 'Download File'}
              </Button>
            </Typography>
          )}
          </Box>
        </Accordion>
      </DashboardLayout>
    </div>
  );
};

export default withScopesHOC(BulkOperations, ['bulk_operations'])
