import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { updateOrganizationSettings, updateOrganizationFeatureFlags } from "apis/organizations";



const SettingChangeConfirmationDialog = ({
  settingDefinition,
  organizationId,
  open,
  onClose,
  onSettingsUpdated,
  organizationName }) => {


  const onAgreeClicked = async () => {
    const { isFeatureFlags = false } = settingDefinition;
    const saveSettingApi = isFeatureFlags ? updateOrganizationFeatureFlags : updateOrganizationSettings
    const { data } = await saveSettingApi(organizationId, { ...settingDefinition, value: JSON.parse(settingDefinition.value) })
    onSettingsUpdated(data)
  }


  if (!settingDefinition || !organizationId) return <></>;

  return (<Dialog
    open={open && settingDefinition.definition_id && organizationId}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Confirm settings update"}
    </DialogTitle>
    <DialogContent>
      Are you sure you want to change setting <strong>{settingDefinition.name}</strong> for <strong>{organizationName}</strong>
      <br />From: <strong>{JSON.stringify(settingDefinition.from_value)}</strong>
      <br />To: <strong>{JSON.stringify(settingDefinition.value)}</strong>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Disagree</Button>
      <Button onClick={onAgreeClicked} >
        Agree
      </Button>
    </DialogActions>
  </Dialog>)
}

export default SettingChangeConfirmationDialog;