import { OrganizationIconWrapper, StyledHeaderCard, TitleTextWrapper } from "./OrganizationDetails.styled"

import { ReactComponent as OrganizationIcon } from "../../icons/organization.svg";
import { Flex, StyledText } from "styled/styledComponent";
import { MAIN_TEXT_COLOR } from "./OrganizationDetails.consts";
import { Alert, Button, Snackbar } from "@mui/material";
import SensorsIcon from "custom-components/SensorsIcons";
import _ from "lodash";
import { setOrganizationSupportAccount } from "apis/organizations";
import { useState } from "react";


const getOrganizationIcon = () => (
  <OrganizationIconWrapper>
    <OrganizationIcon fontSize="large" />
  </OrganizationIconWrapper>)

const countProviders = (providerStr) => {
  if (!providerStr) {
    return [];
  }
  const providers = providerStr.split(', ');
  const providerCount = [];
  
  providers.forEach(provider => {
    const providerName = provider.split(' ')[0];
    const existingProviderIndex = providerCount.findIndex(p => p.sensor_name === providerName);

    if (existingProviderIndex !== -1) {
      providerCount[existingProviderIndex].count++;
    } else {
      providerCount.push({ sensor_name: providerName, count: 1 });
    }
  });

  return providerCount;
};

const OrganizationDetailsColumn = ({ title = '', details = [] }) => {
  return (
    <Flex flex={1} padding="32px 0px 0px 0px" direction='column'>
      <StyledText size={12} color='#7B809A' fontWeight={700} minHeight={20}>
        {title}
      </StyledText>
      <Flex direction='row' width="60%" justify="space-between" >
        <Flex direction='column' height='80px' justify="space-between">
          {details.map(({ title: itemTitle }) => <StyledText fontWeight='700' color={MAIN_TEXT_COLOR}>{itemTitle}:</StyledText>)}
        </Flex>
        <Flex direction='column' height='80px' justify="space-between">
          {details.map(({ ValueComponent }) => ValueComponent || <div>-</div>)}
        </Flex>
      </Flex>
    </Flex>)
}

const OrganizationSummary = ({ organizationDetails = {}, organizationSensorsMap = {} }) => {
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const hideSnackbar = () => setSnackbarMessage(null)
  const setSupportAccount = async () => {
    try {
      await setOrganizationSupportAccount(organizationDetails.id)
      setSnackbarMessage("Support account was set successfully!")
    } catch (exp) {
    }
  }

  return (
    <>
      <StyledHeaderCard>
        <Flex width="100%" >
          <Flex flex={1}>
            {getOrganizationIcon()}
            <TitleTextWrapper>
              <StyledText fontWeight={700} size={20} color={MAIN_TEXT_COLOR}>{organizationDetails.linearb_organization_name}</StyledText>
              <StyledText size={14} color={MAIN_TEXT_COLOR}>Org. ID: {organizationDetails.id}</StyledText>
            </TitleTextWrapper>
          </Flex>
          <Button onClick={setSupportAccount}>Support</Button>
        </Flex>
        <Flex padding="0px 16px">
          <OrganizationDetailsColumn title="General Info" details={
            [
              { title: 'License Type', ValueComponent: <StyledText color={MAIN_TEXT_COLOR}>{organizationDetails.license_plan_name}</StyledText> },
              { title: "Account Type", ValueComponent: <StyledText color={MAIN_TEXT_COLOR}>{organizationDetails.account_type}</StyledText> },
              // If organizationDetails.account_type === Trial, show trial expiration date instead of expiration date else show expiration date
              { title: "Expiration Date", ValueComponent: <StyledText color={MAIN_TEXT_COLOR}>{
                organizationDetails.account_type === "Trial" ? organizationDetails.trial_expiration : (organizationDetails.expiration_date || "-")
                }</StyledText> },
            ]
          } />
          <OrganizationDetailsColumn details={
            [
              { title: "Contributors Count", ValueComponent: <StyledText color={MAIN_TEXT_COLOR}>{organizationDetails.contributor_count}</StyledText> },
              { title: "Teams Count", ValueComponent: <StyledText color={MAIN_TEXT_COLOR}>{organizationDetails.teams_count}</StyledText> },
              { title: "Active Repos", ValueComponent: <StyledText color={MAIN_TEXT_COLOR}>{organizationDetails.repositories_count}</StyledText> },

            ]} />
          <OrganizationDetailsColumn title="Connectors" details={
            [
              {
                title: "Git Provider",
                ValueComponent: 
                <Flex direction="row"> 
                {countProviders(organizationDetails.git_provider).map(({ sensor_name: name }) => <SensorsIcon name={name.toLowerCase()} />)}
                </Flex>
              }, {
                title: "PM Provider",
                ValueComponent: <Flex direction="row"> {_.get(organizationSensorsMap, 'Project Management', []).map(({ sensor_name: name }) => <SensorsIcon name={name.toLowerCase()} />)}</Flex>
              },
              {
                title: "Notification Provider",
                ValueComponent: <Flex direction="row">{_.get(organizationSensorsMap, 'Collaboration', []).map(({ sensor_name: name }) => <SensorsIcon name={name.toLowerCase()} />)}</Flex>,
              }
            ]
          } />

        </Flex>

      </StyledHeaderCard>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(snackbarMessage)} autoHideDuration={6000} onClose={hideSnackbar}>
        <Alert onClose={hideSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </>


  )
}


export default OrganizationSummary;