import { JWT } from "constants/local_storage";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const useAuthenticate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();


  useEffect(() => {
    const jwt = localStorage.getItem(JWT);

    if (!jwt || jwt.trim() === '') {
      navigate('/login');
    }
  }, []);
}

export default useAuthenticate;