import Axios from "./axiosClient"
import { API_ENDPOINT } from "../constants/apis"


export const getOrganizations = ({ page = 1, limit = 50,
                                   searchParam = null, isPaid = true,
                                   isTrial = false } = {}) => {
  return Axios.get(`${API_ENDPOINT}/organizations/summary`, {
    params: {
      page: page,
      limit: limit,
      search_param: searchParam,
      is_paid: isPaid,
      is_trial: isTrial
    }
  })
}

export const getOrganizationDetails = (organizationId) => {
  return Axios.get(`${API_ENDPOINT}/organizations/${organizationId}/details`)
}

export const getOrganizationSensors = (organizationId) => {
  return Axios.get(`${API_ENDPOINT}/organizations/${organizationId}/sensors`)
}

export const getOrganizationSettings = (organizationId) => {
  return Axios.get(`${API_ENDPOINT}/organizations/${organizationId}/settings`)
}

export const updateOrganizationSettings = (organizationId, payload) => {
  return Axios.put(`${API_ENDPOINT}/organizations/${organizationId}/settings`, payload)
}
export const updateOrganizationFeatureFlags = (organizationId, payload) => {
  return Axios.put(`${API_ENDPOINT}/organizations/${organizationId}/feature_flags`, payload)
}
export const setOrganizationSupportAccount = (organizationId) => Axios.put(`${API_ENDPOINT}/organizations/${organizationId}/support`)