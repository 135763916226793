import React, {useMemo, useState} from 'react'
import {StyledTableCell, StyledTableRow, StyledText} from 'styled/styledComponent';
import {getOPAs, updateOrganizationData} from "../../apis/opas";
import SensorsIcon from "../../custom-components/SensorsIcons";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FilteredTable from "../../custom-components/FilteredTable";
import {Alert, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";


export const ON_PREMISE_TABLE_COLUMNS = [
    {title: 'Organization Name', width: '15%', color: '#7B809A', fontSize: "10px"},
    {title: 'Organization ID', width: '15%', color: '#7B809A', fontSize: "10px"},
    {title: 'OPA Version', width: '10%', color: '#7B809A', fontSize: "10px"},
    {title: 'On Boarded At', width: '10%', color: '#7B809A', fontSize: "10px"},
    {title: 'License Type', width: '10%', color: '#7B809A', fontSize: "10px"},
    {title: 'License Plan', width: '10%', color: '#7B809A', fontSize: "10px"},
    {title: 'Trial End Date', width: '10%', color: '#7B809A', fontSize: "10px"},
    {title: 'Integrations', width: '10%', color: '#7B809A', fontSize: "10px"},
    {title: 'Agent Type', width: '10%', color: '#7B809A', fontSize: "10px"},
    { title: 'gitStream Installed', width: '10%', color: '#7B809A', fontSize: '10px' },
    { title: 'gitStream Last Activity', width: '10%', color: '#7B809A', fontSize: '10px' }
]
const agentTypeOptions = [
    'LinearB AWS EKS',
    'Shared AWS EKS',
    'LinearB Azure AKS',
    'Shared Azure AKS',
    'Self-hosted k3d',
    'docker-compose'
];

const toggleStates = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
};

const OnPremiseApp = () => {
    const [onPremApps, setOnPremApps] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const navigate = useNavigate();

    const hideSnackbar = () => setSnackbarMessage(null);

    const updateOnPremApps = async (toggle, currPage, currRowsNum, search) => {
        let validateLicense = true;
        if (toggle === toggleStates.INACTIVE) {
            validateLicense = false;
        }
        let result = [];
        try {
            const {data: orgDataResp} = await getOPAs(
                {
                    'page': currPage + 1, 'limit': currRowsNum,
                    'searchParam': search, 'has_valid_license': validateLicense
                });
            setOnPremApps(orgDataResp);
            result = orgDataResp;
        }  catch (exp) {
            setSnackbarMessage("Failed to load OPA organizations")
            console.error(exp, exp.stack);
        }
        return result;
    };

    const getTimeDifferenceInHours = (utcDateInSeconds) => {
        const inputTime = new Date(utcDateInSeconds * 1000);
        const currentTime = new Date();
        currentTime.setUTCHours(
            currentTime.getUTCHours(),
            currentTime.getUTCMinutes(),
            currentTime.getUTCSeconds(),
            0);

        const timeDifference = currentTime - inputTime;
        // Getting the diff hours
        return timeDifference / (1000 * 60 * 60);
    }

    const onSelectAgentType = async (event, orgId) => {
        const updatedData = [...onPremApps];
        const index = updatedData.findIndex(entry => entry.org_id === orgId);

        // Update the data field of the specific entry
        if (index !== -1) {
            updatedData[index].opa_type = event.target.value;
            setOnPremApps(updatedData);
        }
        try {
            await updateOrganizationData(orgId,
                {'opa_type': event.target.value});
        } catch (exp) {
            setSnackbarMessage("Failed to update OPA type")
            console.error(exp, exp.stack);
        }
    };

    const tableRows = useMemo(() => {
        return onPremApps.map((opas) => {
            let trialEnd = new Date(opas.trial_end_date);
            let colorText = '';
            let currDate = new Date();
            currDate.setHours(0, 0, 0, 0);
            if (trialEnd < currDate) {
                colorText = 'red';
            }
            let lastHeartbeat = opas.opa_last_heartbeat;
            let orgColor = '';
            let orgTitle = '';
            if (lastHeartbeat !== null && lastHeartbeat !== '') {
                // We want to display the last heartbeat if exits
                orgTitle = 'Last Heartbeat: ' + new Date(lastHeartbeat * 1000);
                if (getTimeDifferenceInHours(lastHeartbeat) > 3) {
                    orgColor = '#ffcdce';
                }
            }
            return (
                <StyledTableRow key={opas.org_id + 'StyledTableRow'} style={{backgroundColor: orgColor}}>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell1'}>
                        <StyledText title={orgTitle} fontWeight={700}>{opas.org_name}</StyledText>
                    </StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell2'}>
                        <button onClick={() => {
                            navigator.clipboard.writeText(opas.org_id)
                        }}>
                            {opas.org_id}
                        </button>
                    </StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell3'}>{opas.opa_version}</StyledTableCell>
                    <StyledTableCell
                        key={opas.org_id + 'StyledTableCell4'}>{opas.finish_on_boarded_at}</StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell5'}>{opas.license_type}</StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell6'}>{opas.license_plan}</StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell7'} color={colorText}>
                        {opas.trial_end_date}
                    </StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell8'}>
                        {[].concat(opas.sensor_info).sort(
                            function (a, b) {
                                if (a.toLowerCase() < b.toLowerCase()) return -1;
                                if (a.toLowerCase() > b.toLowerCase()) return 1;
                                return 0;
                            }).map(function (text) {
                                let sensData = text.split('__');
                                let sensorName = sensData[0]
                                let tooltipText =
                                    sensorName.charAt(0).toUpperCase() + sensorName.slice(1) + ' - status: '
                                    + sensData[1].charAt(0).toUpperCase() + sensData[1].slice(1);
                                let disabled = sensData[1] === 'failing';
                                return <span style={{opacity: disabled ? 0.25 : 1}}
                                             title={tooltipText + ' ' + sensData[2]}>
                                    <SensorsIcon name={sensorName.toLowerCase()}/>
                                </span>;
                            }
                        )}
                    </StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell9'}>
                        <FormControl key={opas.org_id + 'FormControl'} fullWidth>
                            <Select
                                value={opas.opa_type}
                                key={opas.org_id}
                                autoWidth
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                onChange={(e) => {
                                    onSelectAgentType(e, opas.org_id)
                                }}
                            >
                                <MenuItem key={opas.org_id + 'MenuItem'} value=""><em>NA</em></MenuItem>
                                {agentTypeOptions.map((option) => {
                                    return (<MenuItem key={option} value={option}>{option}</MenuItem>);
                                })}

                            </Select>
                        </FormControl>
                    </StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell10'}>{opas.gs_installed? 'True' : 'False'}</StyledTableCell>
                    <StyledTableCell key={opas.org_id + 'StyledTableCell11'}>{opas.gs_last_activity}</StyledTableCell>
                </StyledTableRow>
            )
        });
    }, [onPremApps]);

    return (
        <div>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={Boolean(snackbarMessage)} onClose={hideSnackbar}>
                <Alert onClose={hideSnackbar} severity="error" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <FilteredTable
                defaultState={toggleStates.ACTIVE}
                toggleStatesList={toggleStates}
                tableRows={tableRows}
                tableColumns={ON_PREMISE_TABLE_COLUMNS}
                updateData={updateOnPremApps}
                tableId={'OPATable'}
                additionalButtonText='Create New OPA Organization'
                showAdditionalButton={true}
                onAdditionalButtonClick={() => navigate('/onboarding/')}

            />
        </div>
    );
}

export default OnPremiseApp;