import {Avatar, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton} from '@mui/material';
import { style } from '@mui/system';
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

export const CommonTd = css`
  height: 60px;
  width: 13.936%;
  padding-left: 12px;
  line-height: 60px;
  box-shadow: inset 0px -1px 0px #ebeef5;
`;

export const commonTdTop = css`
  height: 60px;
  width: 13.936%;
  padding-left: 12px;
  line-height: 60px;
`;
export const TableStyled = styled.table`
  width: 96%;
  text-align: left;
  font-size: 14px;
  margin: auto;
`;
export const TheadStyled = styled.thead`
  height: 4rem;
  width: 100%;
  // background-color: blue;
`;
export const TbodyStyled = styled.tbody`
  width: 100%;
  height: 70px;
`;
export const TrStyled = styled.tr`
  display: flex;
  background-color: ${(props) => (props.backgroundColor ||  '#F5F7FA')};
   color: #4451BF
  width: 100%;
`;
export const LineStyled = styled.hr`
  height: 0.1px;
  margin: 0;
  color: red;
  border-width: 0.2px;
`;
export const ThStyled = styled.tr`
  display: flex;
  height: 33px;
  width: 100%;
  color: #909192;
  // background-color: blue;
`;
export const StandartTd = styled.td`
  ${CommonTd};
  width: ${(props) => (props.width ? props.width : '15rem')};
  // width: 15rem;
  box-shadow: inset 0px -1px 0px #ebeef5;
  box-shadow: ${(props) =>
    !props.top ? 'inset 0px -1px 0px #EBEEF5' : 'none'};
    // background-color: blue;
    color: ${(props) => (props.color ? props.color  : '#4451BF')};
`;

export const NameTd = styled.td`
  ${CommonTd};
  width: ${(props) => (props.width ? props.width : '15rem')};
  // width: 15rem;
  box-shadow: inset 0px -1px 0px #ebeef5;
  box-shadow: ${(props) =>
    !props.top ? 'inset 0px -1px 0px #EBEEF5' : 'none'};
    // background-color: blue;
  
`;



export const CheckBoxStyled = styled.img`
  margin: 0 auto;
`;
export const CommonIconStyle = css`
  position: relative;
  top: 3px;
`;


export const FeatureFlagAvatar = styled.div`
    width: 40px;
    height: 40px;
    border: solid 1px #4451BF;
    border-radius: 40px;
    display: flex;
    text-align: center; 
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    color: #4451BF;
`;
 


export const DescriptionStyled = styled.p`
    // display: flex;
    position: relative;
    top: -50px;
    width: ${(props) => (props.width ? props.width : '15rem')};

`;

export const SearchStyled = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 32px;
  outline: 1px solid rgb(195, 195, 195);
  padding-left: 16px;
  gap: 10px;

`;

export const InputStyled = styled.input`
  width: 15rem;
  height: 28px;
  border-radius: 30px;
  border: ${({ noBorder }) =>
    noBorder ? 'none' : '1px solid rgb(247, 247, 247)'};
  color: #61656c;
  outline: none;
  background-color: #00000000;
`;

export const SearchContainer = styled.div`
    width: 15rem;
`;

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({

  "&.Mui-selected": {
   color: "#fff",
   backgroundColor: "#346eeb",
    "&:hover": {
     color: "#fff",
     backgroundColor: "#346eeb",
    },
  },
  "&:hover": {
   color: "#fff",
   backgroundColor: "#34e5eb",
 },
}));

export const StyledText = styled.div`
  font-size: ${({ size }) =>
    size || 14}px;
  ${({ padding }) => padding ? `padding: ${padding}` : ''};
  font-weight: ${({ fontWeight }) =>
    fontWeight || 400};
  ${({ color }) => color ? `color: ${color}` : ''};
  ${({ minHeight }) => minHeight ? `min-height: ${minHeight}px` : ''};
`
export const Flex = styled.div`
  display: flex;
  ${({ flex }) => flex ? `flex: ${flex}` : ''};
  ${({ width }) => width ? `width: ${width}` : ''};
  ${({ padding }) => padding ? `padding: ${padding}` : ''};
  ${({ margin }) => margin ? `margin: ${margin}` : ''};
  ${({ marginRight }) => marginRight ? `margin-right: ${marginRight}` : ''};
  ${({ marginTop }) => marginTop ? `margin-top: ${marginTop}` : ''};
  ${({ direction }) => direction ? `flex-direction: ${direction}` : ''};
  ${({ alignment }) => alignment ? `align-items: ${alignment}` : ''};
  ${({ justify }) => justify ? `justify-content: ${justify}` : ''};
  ${({ height }) => height ? `height: ${height}` : ''};
  ${({ items }) => items ? `align-items: ${items}` : ''};
  ${({ border }) => border ? `border: ${border}` : ''};
  ${({ borderRadius }) => borderRadius ? `border-radius: ${borderRadius}` : ''};
  ${({ cursor }) => cursor ? `cursor: ${cursor}` : ''};

`
export const StyledTable = styled(Table)`
  ${({ width }) => width ? `width: ${width}` : ''};

`
export const StyledTableHead = styled(TableHead)`
  ${({ width }) => width ? `width: ${width}` : ''};
  display: contents;
 `
 export const StyledTableRow = styled(TableRow)`
  ${({ width }) => width ? `width: ${width}` : ''};
`
export const StyledTableCell = styled(TableCell)`
  ${({ width }) => width ? `width: ${width}` : ''};
  ${({ color }) => color ? `color: ${color}` : ''};
  ${({ fontSize }) => fontSize ? `font-size: ${fontSize}` : ''};
`

export const StyledTableBody = styled(TableBody)`
  width: 100%;
 `;

 export const StyledAvatar = styled(Avatar)`
  ${({ backgroundColor }) => backgroundColor ? `background-color: ${backgroundColor}` : ''};
  ${({ border }) => border ? `border: ${border}` : ''};
  ${({ color }) => color ? `color: ${color}` : ''};
  ${({ fontWeight }) => fontWeight ? `font-weight: ${fontWeight}` : ''};
  ${({ width }) => width ? `width: ${width}` : ''};
  ${({ height }) => height ? `height: ${height}` : ''};
  ${({ margin }) => margin ? `margin: ${margin}` : ''};


 `