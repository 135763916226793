import React from 'react'
import { JWT } from 'constants/local_storage';
import jwt_decode from "jwt-decode";

const hasRequiredScopes = (requiredScopes) => {
   const jwt = localStorage.getItem(JWT);
  const { backoffice_scopes } = jwt_decode(jwt);
  const scopes = JSON.parse(backoffice_scopes) ||[]
  return requiredScopes.every(item => scopes?.includes(item))
}

const withScopesHOC = (WrappedComponent, requiredScopes) => 
  (props) => 
     (
      <WrappedComponent {...props} hasRequiredScopes={hasRequiredScopes(requiredScopes)} />
    );

 
export default withScopesHOC;