import React, { useEffect, useState } from 'react'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Alert, Button, Card, CircularProgress, Snackbar, TextField } from '@mui/material';
import styled from 'styled-components';
import { getProjectsConfig, saveProjectsConfig } from 'apis/projects';
import { Flex, StyledText } from 'styled/styledComponent';
import ReactJson from 'react-json-view'
import withScopesHOC from 'HOC/withScopesHOC';

const StyledCard = styled(Card)`
  padding: 20px;
  margin-bottom: 20px;
`

const StyledCircularProgress = styled(CircularProgress)`
  width: 30px;
  height: 30px;
  margin-left: 20px;
`


const Projects = ({ hasRequiredScopes }) => {

  const [organizationId, setOrganizationId] = useState('')
  const [configJson, setConfigJson] = useState({})
  const [loading, setLoading] = useState(false)
  const [edited, setEdited] = useState(false)
  const [editedProjects, setEditedProjects] = useState([])
  const [snackbarProps, setSnackbarProps] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  const loadConfigFile = async () => {
    setLoading(true)
    setEdited(false)
    try {
      const { data: configJson } = await getProjectsConfig(organizationId)
      setConfigJson(configJson)
    } catch (exp) {
      setSnackbarProps({ message: 'There was an issue loading projects', severity: 'error' })
    }
    setLoading(false)
  }

  const onJsonUpdate = (({ updated_src, namespace }) => {
    setConfigJson(updated_src)

    if (namespace.length > 1 && namespace[0] === 'projects') {
      setEditedProjects([...editedProjects, namespace[1]])
    }
    setEdited(true)
  })

  const onSaveClicked = async () => {
    setIsSaving(true)
    try {
      await saveProjectsConfig(organizationId, { config: configJson, edited_projects: [...new Set(editedProjects)] })
      setSnackbarProps({ message: 'Projects config was saved successfully!', severity: 'success' })
    } catch (exp) {
      setSnackbarProps({ message: 'There was an issue saving config!', severity: 'error' })
    }
    setIsSaving(false)

    setEdited(false)
    setEditedProjects([])
  }
  const hideSnackbar = () => setSnackbarProps({})

  return (
    <DashboardLayout>
      <StyledCard>
        Please enter the organization id for which to load config file
        <Flex alignment='center' justify="space-between">
          <Flex alignment='center' >
            <TextField
              value={organizationId}
              onChange={event => setOrganizationId(event.target.value)}
              type="number"
            />
            {loading ? <StyledCircularProgress size={30} /> : <Button
              disabled={organizationId.trim() === ''}
              onClick={loadConfigFile}>
              Load JSON
            </Button>}
          </Flex>
          {edited ? isSaving ? <StyledCircularProgress size={30} /> : <Button
            onClick={onSaveClicked}>
            Save
          </Button> : <></>}


        </Flex>

      </StyledCard>
      {<StyledCard>
        {configJson && Object.keys(configJson).length ? <ReactJson
          iconStyle="square"

          onAdd={hasRequiredScopes && onJsonUpdate}
          displayObjectSize={false}
          onDelete={hasRequiredScopes && onJsonUpdate}
          onEdit={hasRequiredScopes && onJsonUpdate}
          collapsed={true}
          enableClipboard={false}
          name={null} src={configJson} /> :
          <StyledText>No project was found!</StyledText>}


      </StyledCard>}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Object.keys(snackbarProps).length} autoHideDuration={6000} onClose={hideSnackbar}>
        <Alert onClose={hideSnackbar} severity={snackbarProps.severity} sx={{ width: '100%' }}>
          {snackbarProps.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>


  )
}

export default withScopesHOC(Projects, ['edit_pdt_config']);