import Organizations from "views/Organizations";
import OrganizationDetails from "views/OrganizationDetails";
import Projects from "views/Projects";

import React from 'react'
import {Route} from "react-router-dom";
import {AccountTree, CorporateFare, MapsHomeWork} from "@mui/icons-material";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import LoginPage from "views/Login";
import Oauth from "views/Oauth";
import OPAs from "views/OnPremiseApp/OnPremiseAppsPage";
import OnBoarding from "views/Organizations/OnBoarding/OnBoardingPage";
import BulkOperations from "views/BulkOperations/BulkOperationsAppPage";


export const AUTHENTICATED_ROUTES = [
    {
        type: "collapse",
        name: "Organizations",
        key: "organization",
        icon: <CorporateFare fontSize="small"/>,
        route: "/organization",
        component: <Organizations/>,
    },
    {
        visible: false,
        type: "collapse",
        name: "Organization",
        key: "organization",
        route: "/organization/:id",
        component: <OrganizationDetails/>,
    },
    {
        type: "collapse",
        name: "Projects",
        key: "projects",
        icon: <AccountTree fontSize="small"/>,
        route: "/projects",
        component: <Projects/>,
    },
    {
        type: "collapse",
        name: "OPA Organizations",
        key: "OPAs",
        icon: <MapsHomeWork fontSize="small"/>,
        route: "/opas",
        component: <OPAs/>,
    },
    {
        visible: false,
        type: "collapse",
        name: "On Boarding",
        key: "onboarding",
        route: "/onboarding",
        component: <OnBoarding/>,
    },
    {
        type: "collapse",
        name: 'Bulk operations',
        key: "bulk",
        icon: <DynamicFeedIcon fontSize="small"/>,
        route: "/bulk",
        component: <BulkOperations/>,
    }

];

const AUTH_CALLBACK_ROUTE = {
    route: "/callback/backoffice_google_identity",
    component: <Oauth/>
}
export const LOGIN_ROUTE = {
    name: "Login",
    route: "/login",
    component: <LoginPage/>,
}
const ALL_ROUTES = [...AUTHENTICATED_ROUTES, LOGIN_ROUTE, AUTH_CALLBACK_ROUTE]

export default ALL_ROUTES.map((route) => {
    return <Route exact path={route.route} element={route.component} key={route.key}/>;
});


