import { useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import ROUTES, { AUTHENTICATED_ROUTES, LOGIN_ROUTE } from "routes";
import { useMaterialUIController } from "context";
import React from "react";
import useAuthenticate from "hooks/useAuthenticate";

const App = () => {
  const [controller] = useMaterialUIController();
  const {
    layout,
    sidenavColor,
    darkMode,
  } = controller;
  const { pathname } = useLocation();
  useAuthenticate()

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  console.log(process.env)


  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {pathname !== LOGIN_ROUTE.route && (
        <Sidenav
          color={sidenavColor}
          brandName="LinearB Backoffice"
          routes={AUTHENTICATED_ROUTES.filter(({ visible = true }) => visible)}
        />
      )}
      <Routes>
        {ROUTES}
        <Route path="*" element={<Navigate to="/organization" />} />
      </Routes>
    </ThemeProvider>)
}

export default App;