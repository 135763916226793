import {Box, Button, Card, ToggleButtonGroup} from "@mui/material";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import TablePagination from "@mui/material/TablePagination";
import {CustomToggleButton, StyledText} from "../styled/styledComponent";
import {MAIN_TEXT_COLOR} from "../views/OrganizationDetails/OrganizationDetails.consts";
import CustomTable from "../views/OrganizationDetails/CustomTable";
import DashboardLayout from "../examples/LayoutContainers/DashboardLayout";
import React, {useMemo, useState} from "react";
import styled from "styled-components";

const StyledCard = styled(Card)
    `padding: 20px;`

const FilteredTable = ({defaultState, toggleStatesList, tableRows, tableColumns,
                           updateData, tableId, additionalButtonText = '',
                           showAdditionalButton = false,
                           onAdditionalButtonClick = ''}) => {

    const [searchParam, setSearchParam] = useState('');
    const [toggleMenu, setToggleMenu] = React.useState(defaultState);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [numOfTotalResults, setNumOfTotalResults] = React.useState(0);

    const debounceDelay = 500;
    let debounceTimer;


    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        const currRowsNum = parseInt(event.target.value, 10);
        setRowsPerPage(currRowsNum);
        setPage(0);
    };

    const handleToggleChange = async (event, newToggle) => {
        setToggleMenu(newToggle);
        setPage(0);
    };


    const handleSearchParam = async (event) => {
        let newSearch = event.target.value;
        debouncedHandleSearchParam(newSearch);
    }

    const debouncedHandleSearchParam = (newSearch) => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            setSearchParam(newSearch);
            // Starting from the first page.
            setPage(0);
        }, debounceDelay);
    };

    useMemo(() => {
        (async () => {
            let resData = await updateData(toggleMenu, page, rowsPerPage, searchParam);
            setNumOfTotalResults((resData != null && resData.length > 0) ? resData[0].full_count : 0);
        })()
    }, [toggleMenu, page, rowsPerPage, searchParam]) // Will be rendered when one of these parameters is changed.

    return (
        <DashboardLayout>

            <DashboardNavbar onSearch={event => handleSearchParam(event)}/>
            {showAdditionalButton && (
                <Button
                key="additionalButton"
                variant="contained"
                color="primary"
                onClick= {onAdditionalButtonClick}
                style={{ color: 'white' }}
            >
                    {additionalButtonText}
            </Button>
            )}
            <TablePagination
                component="div"
                count={numOfTotalResults}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <StyledCard>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <ToggleButtonGroup
                        style={{textAlign: "center",}}
                        variant="outlined"
                        color="primary"
                        value={toggleMenu}
                        exclusive
                        onChange={handleToggleChange}
                    >
                        {Object.entries(toggleStatesList).map(
                            ([key, value]) => {
                                return (
                                    <CustomToggleButton key={key} value={value}>
                                        {key}
                                    </CustomToggleButton>
                                );
                            })
                        };

                    </ToggleButtonGroup>
                </Box>

                <StyledText color={MAIN_TEXT_COLOR} size={16} fontWeight={700}>
                    Results ({numOfTotalResults})
                </StyledText>
                <CustomTable
                    tableRows={tableRows}
                    tableWidth="100%"
                    columns={tableColumns}
                    key={tableId}
                />
            </StyledCard>
        </DashboardLayout>
    )
}

export default FilteredTable;