import Axios from "./axiosClient"
import {API_ENDPOINT} from "../constants/apis"

export const handleBulkSlackCsv = (formData) => {    
    
    return Axios.post(`${API_ENDPOINT}/bulk/slack_csv`, formData, 
        {headers:{"Content-Type": "multipart/form-data", "Accept": "application/json"}}
    );
}


export const check_csv_result = (orgId, fileName) => {    
    
    return Axios.get(`${API_ENDPOINT}/bulk/check_csv_result/${orgId}/${fileName}`, {});
}

export const downloadCsvResult = (orgId, fileName) => {    
    
    return Axios.get(`${API_ENDPOINT}/bulk/download_csv_result/${orgId}/${fileName}`, {
      responseType: 'blob'
    });
}