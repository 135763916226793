import React, {useState} from 'react'
import {Alert, Button, Card, Snackbar, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import {onBoardNewOrganization} from "../../../apis/opas";
import styled from 'styled-components';

const StyledCard = styled(Card)`
  padding: 20px;
  margin-bottom: 20px;
`


const OnBoarding = () => {
    const [orgName, setOrgName] = useState('');
    const [orgId, setOrgId] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [accountName, setAccountName] = useState('');
    const [accountEmail, setAccountEmail] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [emailError, setEmailError] = useState('');
    const [token, setToken] = useState(null);

    const hideSnackbar = () => setSnackbarMessage(null);

    function isValidEmail(email) {
        // Regular expression for validating email addresses
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleOrgNameChange = (event) => {
        setOrgName(event.target.value);
    };

    const handleAccountNameChange = (event) => {
        setAccountName(event.target.value);
    };

    const handleEmailChange = (event) => {
        let email = event.target.value;
        setAccountEmail(email);
        if (!isValidEmail(email)) {
            setEmailError('Invalid email address');
        } else {
            setEmailError('');
        }
    };


    const createNewOrganization = async () => {
        setOrgId(null);
        setAccountId(null);
        setIsLoading(true);
        setToken(null);
        try {
            const res = await onBoardNewOrganization(orgName, accountName, accountEmail);
            if (res) {
                const {data} = res;
                if (data) {                
                    const [newOrgId, newAccountId, msg, newToken] = data;
                    if (newOrgId) {
                        setOrgId(newOrgId);
                    }            
                    if (newAccountId) {
                        setAccountId(newAccountId);
                    }
                    if (newToken) {
                        setToken(newToken);
                    }
                    if (msg) {
                        setSnackbarMessage("Organization was not created: " + msg)
                    }
                } else {
                    setSnackbarMessage("Failed to create the organization, no result was returned from the server");
                    console.error("Failed to create the organization, no result from the server");
                }
            } else {
                setSnackbarMessage("Failed to create the organization, no result was returned from the server.");
                console.error("Failed to create the organization, no result from the server");
            }
        }  catch (exp) {
            setSnackbarMessage("Failed to create the organization.")
            console.error(exp, exp.stack);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={Boolean(snackbarMessage)} onClose={hideSnackbar}>
                <Alert onClose={hideSnackbar} severity="error" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <DashboardLayout>
                <StyledCard>Create New Organization</StyledCard>
                <StyledCard>
                    <TextField
                        key="orgName"
                        label="Organization Name"
                        variant="outlined"
                        fullWidth
                        value={orgName}
                        onChange={handleOrgNameChange}
                        margin="normal"
                    />
                    <TextField
                        key="accountName"
                        label="Account Name"
                        variant="outlined"
                        fullWidth
                        value={accountName}
                        onChange={handleAccountNameChange}
                        margin="normal"
                    />
                    <TextField
                        key="accountEmail"
                        label="Account Email"
                        variant="outlined"
                        fullWidth
                        value={accountEmail}
                        onChange={handleEmailChange}
                        margin="normal"
                        error={Boolean(emailError)} // Set error state based on emailError
                        helperText={emailError} // Display error message if emailError is not empty
                    />
                    <Button
                        key="createOrg"
                        variant="contained"
                        color="primary"
                        onClick={createNewOrganization}
                        disabled={!orgName || !accountName || !accountEmail || isLoading || Boolean(emailError)}
                    >
                        Create Organization
                    </Button>
                </StyledCard>
                {orgId !== null && (
                    <Typography variant="h6">
                        New Organization ID: {orgId}                        
                    </Typography>
                )}
                {accountId !== null && (
                    <Typography variant="h6">
                        New Account ID: {accountId}                        
                    </Typography>
                )}
                {token !== null && (
                    <Typography variant="h6">
                        New Token ID: {token}                        
                    </Typography>
                )}
            </DashboardLayout>
        </div>
    );
}

export default OnBoarding;